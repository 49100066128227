// When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

// Get the header
var header = document.getElementById("gh-head");

// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}


//thank you Florin Pop
var element =  document.getElementById('progress');
  if (typeof(element) != 'undefined' && element != null) {
  let prog = document.getElementById('progress');

  let body = document.body,
      html = document.documentElement;

  let height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
  );

  const setProgress = () => {
      let scrollFromTop = (html.scrollTop || body.scrollTop) + html.clientHeight;
      let width = (scrollFromTop / height) * 100 + '%';

      prog.style.width = width;
  };

  window.addEventListener('scroll', setProgress);

  setProgress();

  headerHeight = $('#gh-head').outerHeight();
  $('#progress').css({"top": headerHeight});

  //Delay elements
  $(function() {
    setTimeout(function(){
        showElement();
    }, 1000);

    function showElement() {
        $('#progress').toggleClass('show');
    }
  });

}
